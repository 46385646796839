import "./styles.scss";

import React from "react";
import classnames from "classnames";

import AboutInvestmentsIcon from "icons/outlines/o-inwestycji.svg";
import InvestmentsIcon from "icons/outlines/inwestycji.svg";
import AttractionsIcon from "icons/outlines/atrakcje.svg";

const mainClass = "outline-text";

const OutlineText = ({ text }) => {
  const text_variants = () => {
    switch (text) {
      case "about-investment":
        return <AboutInvestmentsIcon />;
      case "investments":
        return <InvestmentsIcon />;
      case "attractions":
        return <AttractionsIcon />;
      default:
        return null;
    }
  };

  return <div className={classnames(mainClass)}>{text_variants()}</div>;
};

export default OutlineText;
