import "./styles.scss";

import React from "react";
import classnames from "classnames";

import FeaturesGrid from "components/FeaturesGrid";

import MapIcon from "images/map.png";

const mainClass = "home-location";

const legend_data = [
  {
    label: "punkty strategiczne",
    items: [
      "CH Manufaktura",
      'Przystanek kolei średnicowej "Małe metro"',
      "Uniwersytet Medyczny w Łodzi",
      "Filharmonia Łódzka im. Artura Rubinsteina",
      'Dworzec kolejowy "Łódź Farbryczna"',
      "Uniwersytet Łódzki",
    ],
  },
  {
    label: "Parki",
    items: [
      "Park im. Henryka Sienkiewicza",
      "Park im. Stanisława Moniuszki",
      "Park Staromiejski",
      "Park Helenów",
      "Park im. ks. J. Poniatowskiego",
      "Park im. Stanisława Staszica",
    ],
  },
];

const Location = ({ text, items }) => {
  return (
    <section id="lokalizacja-section" className={mainClass}>
      <div className="container container--medium">
        <div className={`${mainClass}__left`}>
          <h2>Lokalizacja</h2>

          <p
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
          <FeaturesGrid data={items} />
        </div>
        <div className={`${mainClass}__right`}>
          <img src={MapIcon} alt="" />
          <div className={`${mainClass}__right__legend`}>
            <h3>Legenda</h3>
            <div className={`${mainClass}__right__legend__wrapper`}>
              {legend_data?.map(({ label, items }, index) => (
                <div
                  key={index}
                  className={`${mainClass}__right__legend__item`}
                >
                  <span
                    className={classnames(
                      `${mainClass}__right__legend__item__status`,
                      {
                        [`${mainClass}__right__legend__item__status--green`]:
                          !!index,
                      }
                    )}
                  >
                    {label}
                  </span>
                  <ol>
                    {items?.map((item, index) => (
                      <li
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: item,
                        }}
                      />
                    ))}
                  </ol>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Location;
