import "./styles.scss";

import React from "react";
import { Link } from "gatsby";
import classnames from "classnames";
import moment from "moment/moment";
import "moment/locale/pl";

import priceFormat from "../../utils/priceFormat";

import HiddenGardenIcon from "icons/hidden-garden.svg";

const mainClass = "apartments-grid";

const ApartmentsGrid = ({ data }) => {
  data.sort(function (a, b) {
    if (a.acfApartments?.number < b.acfApartments?.number) {
      return -1;
    }
    if (a.acfApartments?.number > b.acfApartments?.number) {
      return 1;
    }
    return 0;
  });

  return (
    <div className={mainClass}>
      {data?.map(({ uri, acfApartments }, index) => (
        <Link
          to={!!!acfApartments?.availableDate ? uri : "#"}
          key={index}
          className={classnames(`${mainClass}__item`, {
            [`${mainClass}__item--prevent-event`]:
              !!acfApartments?.availableDate,
          })}
        >
          <div className={`${mainClass}__item__image`}>
            {!!!acfApartments?.availableDate ? (
              <img
                src={acfApartments?.gallery?.[0]?.localFile?.publicURL}
                alt=""
              />
            ) : (
              <div className={`${mainClass}__item__image__placeholder`}>
                <HiddenGardenIcon />
                <span>
                  Oferta dostępna od{" "}
                  {moment(acfApartments?.availableDate, "DD/MM/YYYY")?.format(
                    "DD MMMM"
                  )}
                </span>
              </div>
            )}
          </div>
          <div className={`${mainClass}__item__text`}>
            <span>{`${acfApartments?.type} ${acfApartments?.number}`}</span>
            <span>{priceFormat(acfApartments?.price)}</span>
            <span>
              {acfApartments?.area} m<sup>2</sup>
            </span>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default ApartmentsGrid;
