import "react-image-lightbox/style.css";
import "./styles.scss";

import React, { useState, useRef } from "react";
import Lightbox from "react-image-lightbox";
import classnames from "classnames";
import Slider from "react-slick";

import ArrowIcon from "icons/arrow.svg";

const mainClass = "gallery";

const CustomArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button>
      <ArrowIcon className={className} onClick={onClick} />
    </button>
  );
};

const Gallery = ({ items, variant, size }) => {
  const slider = useRef(null);
  const [current_slide, setCurrentSlide] = useState(0);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const slider_settings = {
    arrows: false,
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    variableWidth: true,
    adaptiveHeight: true,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
    responsive: [
      {
        breakpoint: 660,
        settings: {
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--${variant}`]: !!variant,
        [`${mainClass}--${size}`]: !!size,
      })}
    >
      <Slider
        ref={slider}
        className={`${mainClass}__slider`}
        {...slider_settings}
      >
        {items?.map(({ localFile }, index) => (
          <div key={index}>
            <div
              className={`${mainClass}__slider__item`}
              onClick={(e) => {
                e.preventDefault();
                setIsLightboxOpen(true);
                setPhotoIndex(index);
              }}
              style={{
                backgroundImage: `url(${localFile?.publicURL})`,
                width: localFile?.childrenImageSharp?.[0]?.original?.width,
              }}
            />
          </div>
        ))}
      </Slider>
      <div className={`${mainClass}__navigation`}>
        <CustomArrow
          className={`${mainClass}__navigation__arrow ${mainClass}__navigation__arrow--prev`}
          onClick={() => slider?.current?.slickPrev()}
        />
        <h3>{`${current_slide + 1}/${items?.length}`}</h3>
        <CustomArrow
          className={`${mainClass}__navigation__arrow ${mainClass}__navigation__arrow--next`}
          onClick={() => slider?.current?.slickNext()}
        />
      </div>
      {isLightboxOpen && (
        <Lightbox
          mainSrc={items[photoIndex]?.localFile?.publicURL}
          nextSrc={items[(photoIndex + 1) % items.length]}
          prevSrc={items[(photoIndex + items.length - 1) % items.length]}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + items.length - 1) % items.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % items.length)
          }
        />
      )}
    </div>
  );
};

export default Gallery;
