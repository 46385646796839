import "./styles.scss";

import React from "react";

import Gallery from "components/Gallery";
import OutlineText from "components/OutlineText";

const mainClass = "home-gallery";

const GallerySection = ({ data }) => {
  return (
    <section id="galeria-section" className={mainClass}>
      <h2>Galeria</h2>
      <OutlineText text="investments" />
      <Gallery items={data} variant="light" />
    </section>
  );
};

export default GallerySection;
