import "./styles.scss";

import React from "react";

import Gallery from "components/Gallery";

const mainClass = "home-stained-glass";
// localFile?.publicURL
const StainedGlass = ({ text, image }) => {
  return (
    <section id="witraze-section" className={mainClass}>
      <h2>Witraże wykonane na zamówienie</h2>
      <p
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
      <Gallery items={image} variant="light" size="small" />
    </section>
  );
};

export default StainedGlass;
