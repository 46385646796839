import "./styles.scss";

import React from "react";

const mainClass = "features-grid";

const FeaturesGrid = ({ data }) => {
  return (
    <div className={mainClass}>
      {data?.map(({ icon, text }, index) => (
        <div key={index} className={`${mainClass}__item`}>
          <div className={`${mainClass}__item__icon`}>
            <img src={icon?.localFile?.publicURL} alt="" />
          </div>
          <span
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default FeaturesGrid;
