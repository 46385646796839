import "./styles.scss";

import React from "react";
import classnames from "classnames";

const mainClass = "home-realizations";

const Realizations = ({ for_sell_items, done_items }) => {
  const realizations_items = [
    {
      header: "W sprzedaży",
      items: for_sell_items,
    },
    {
      header: "Zrealizowane",
      items: done_items,
    },
  ];

  return (
    <section className={mainClass}>
      <div className="container">
        <h2>Nasze realizacje</h2>
        <div className={`${mainClass}__items`}>
          {realizations_items?.map(({ header, items }) =>
            items?.map(({ text, photo, href }, index) => (
              <a
                key={index}
                href={href || "#"}
                target="_blank"
                rel="noopener noreferrer"
                className={classnames(`${mainClass}__items__item`, {
                  [`${mainClass}__items__item--prevent`]: !!!href,
                })}
              >
                {index === 0 && <h4>{header}</h4>}
                <div key={index} className={`${mainClass}__items__item__image`}>
                  <img src={photo?.localFile?.publicURL} alt="" />
                </div>
                <h5
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
                {!!href && <span>Zobacz</span>}
              </a>
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default Realizations;
