import "./styles.scss";

import React from "react";

const mainClass = "home-about";

const About = ({ data }) => {
  return (
    <section id="o-nas-section" className={mainClass}>
      <div className="container container--medium">
        {data?.map(({ text, photo }, index) => (
          <div key={index} className={`${mainClass}__column`}>
            <div className={`${mainClass}__column__text`}>
              {index === 0 && <h2>O nas</h2>}
              <p
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            </div>
            <div className={`${mainClass}__column__image`}>
              <img src={photo?.localFile?.publicURL} alt="" />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default About;
