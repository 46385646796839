import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import Seo from "../components/Layout/components/Seo";
import {
  Hero,
  AboutInvestment,
  Offer,
  StainedGlass,
  Location,
  About,
  Realizations,
  Attractions,
  Advantages,
  GallerySection,
} from "page_components/home";

import scrollToElement from "utils/scrollToElement";

const Home = ({ location, data }) => {
  const { hash, pathname } = location;
  const [init_loading, setInitLoading] = useState(false);

  const acfHome = data?.homePage?.nodes?.[0]?.acfHome;
  const apartments = data?.allWpApartment?.nodes;

  useEffect(() => {
    if (!!!hash) return;

    if (!!init_loading) {
      scrollToElement(`${hash.split("?")[0]}-section`);
    } else {
      setTimeout(() => {
        scrollToElement(`${hash.split("?")[0]}-section`);
      }, 100);
    }

    setInitLoading(true);
    // eslint-disable-next-line
  }, [location]);

  return (
    <Layout
      static_header={pathname === "/"}
      contact_data={{
        phone: acfHome?.phone,
        email: acfHome?.email,
        address: acfHome?.address,
      }}
    >
      <Hero
        left_text={acfHome?.heroLeftText}
        right_text={acfHome?.heroRightText}
      />
      <AboutInvestment items={acfHome?.aboutInvestment} />
      <Offer
        header={acfHome?.offerHeader}
        data={acfHome?.offerPositions}
        apartments={apartments}
        own_arrangement_items={acfHome?.ownArrangementItems}
        turnkey_apartments_items={acfHome?.turnkeyApartmentsItems}
      />
      <StainedGlass
        text={acfHome?.stainedGlassText}
        image={acfHome?.stainedGlassImage}
      />
      <Location text={acfHome?.locationText} items={acfHome?.locationItems} />
      <Attractions data={acfHome?.attractionsGallery} />
      <Advantages items={acfHome?.advantagesItems} />
      <GallerySection data={acfHome?.gallery} />
      <About data={acfHome?.aboutItems} />
      <Realizations
        for_sell_items={acfHome?.realizationsForSellItems}
        done_items={acfHome?.realizationsDoneItems}
      />
    </Layout>
  );
};

export const Head = () => (
  <Seo
    title="Wólczańska 4, to wyjątkowe apartamenty inwestycyjne w centrum Łodzi."
    description="Wólczańska 4, to w pełni odnowiona 100 letnia kamienica położona w samym sercu Łodzi, tylko 400 m od ul. Piotrkowskiej. Inwestycja charakteryzuje się kameralną atmosferą i elegancją typową dla przedwojennej łódzkiej architektury."
  />
);

export const query = graphql`
  query {
    homePage: allWpPage(filter: { id: { eq: "cG9zdDo1Mw==" } }) {
      nodes {
        acfHome {
          aboutInvestment {
            header
            text
            photo {
              localFile {
                publicURL
              }
            }
          }
          aboutItems {
            text
            photo {
              localFile {
                publicURL
              }
            }
          }
          advantagesItems {
            header
            text
            photo {
              localFile {
                publicURL
              }
            }
          }
          attractionsGallery {
            localFile {
              publicURL
              childrenImageSharp {
                original {
                  height
                  width
                }
              }
            }
          }
          gallery {
            localFile {
              publicURL
              childrenImageSharp {
                original {
                  height
                  width
                }
              }
            }
          }
          heroLeftText
          heroRightText
          locationItems {
            text
            icon {
              localFile {
                publicURL
              }
            }
          }
          locationText
          offerHeader
          offerPositions {
            header
            items {
              text
            }
          }
          ownArrangementItems {
            text
            icon {
              localFile {
                publicURL
              }
            }
          }
          stainedGlassImage {
            localFile {
              publicURL
              childrenImageSharp {
                original {
                  height
                  width
                }
              }
            }
          }
          stainedGlassText
          turnkeyApartmentsItems {
            text
            icon {
              localFile {
                publicURL
              }
            }
          }
          realizationsDoneItems {
            href
            text
            photo {
              localFile {
                publicURL
              }
            }
          }
          realizationsForSellItems {
            text
            href
            photo {
              localFile {
                publicURL
              }
            }
          }
          address
          phone
          email
        }
      }
    }
    allWpApartment {
      nodes {
        id
        uri
        acfApartments {
          area
          gallery {
            localFile {
              publicURL
            }
          }
          availableDate
          number
          price
          type
          kind
        }
      }
    }
  }
`;

export default Home;
