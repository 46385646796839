import "./styles.scss";

import React from "react";

import OutlineText from "components/OutlineText";

const mainClass = "home-about-investment";

const AboutInvestment = ({ items }) => {
  return (
    <section id="o-inwestycji-section" className={mainClass}>
      <div className="container container--medium">
        <h2>O inwestycji</h2>
        <div className={`${mainClass}__items`}>
          {items?.map(({ header, text, photo }, index) => (
            <div key={index} className={`${mainClass}__item`}>
              <div className={`${mainClass}__item__image`}>
                <img src={photo?.localFile?.publicURL} alt="" />
              </div>
              <div className={`${mainClass}__item__aside`}>
                <div className={`${mainClass}__item__text`}>
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: header,
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: text,
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <OutlineText text="about-investment" />
    </section>
  );
};

export default AboutInvestment;
