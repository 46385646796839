import "./styles.scss";

import React from "react";

import Gallery from "components/Gallery";
import OutlineText from "components/OutlineText";

const mainClass = "home-attractions";

const Attractions = ({ data }) => {
  return (
    <div className={mainClass}>
      <OutlineText text="attractions" />
      <Gallery items={data} />
    </div>
  );
};

export default Attractions;
