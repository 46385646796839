import "./styles.scss";

import React, { useState, useRef } from "react";
import Slider from "react-slick";

import ArrowIcon from "icons/arrow.svg";

const mainClass = "home-advantages";

const CustomArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button>
      <ArrowIcon className={className} onClick={onClick} />
    </button>
  );
};

const Advantages = ({ items }) => {
  const slider = useRef(null);
  const [current_slide, setCurrentSlide] = useState(0);

  const slider_settings = {
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    variableWidth: true,
    // adaptiveHeight: true,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          variableWidth: false,
        },
      },
    ],
  };

  return (
    <div id="atuty-section" className={mainClass}>
      <h2>Atuty inwestycji</h2>
      <Slider
        ref={slider}
        className={`${mainClass}__slider`}
        {...slider_settings}
      >
        {items?.map(({ header, text, photo }, index) => (
          <div key={index}>
            <div className={`${mainClass}__slider__item`}>
              <div className={`${mainClass}__slider__item__photo`}>
                <img
                  draggable={false}
                  src={photo?.localFile?.publicURL}
                  alt=""
                />
              </div>
              <div className={`${mainClass}__slider__item__aside`}>
                <h3
                  dangerouslySetInnerHTML={{
                    __html: header,
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className={`${mainClass}__navigation`}>
        <CustomArrow
          className={`${mainClass}__navigation__arrow ${mainClass}__navigation__arrow--prev`}
          onClick={() => slider?.current?.slickPrev()}
        />
        <h3>{`${current_slide + 1}/${items?.length}`}</h3>
        <CustomArrow
          className={`${mainClass}__navigation__arrow ${mainClass}__navigation__arrow--next`}
          onClick={() => slider?.current?.slickNext()}
        />
      </div>
    </div>
  );
};

export default Advantages;
