import "./styles.scss";

import React from "react";
// import { Link } from "gatsby";

import Apartments from "components/Apartments";

// import OfferBannerImage from "images/offer-banner.jpg";
import CheckmarkIcon from "icons/checkmark.svg";
// import ArrowIcon from "icons/arrow.svg";

const mainClass = "home-offer";

const Offer = ({
	header,
	data,
	apartments,
	own_arrangement_items,
	turnkey_apartments_items,
}) => {
	const turnkey_apartments = apartments?.filter(
		(item) => item?.acfApartments?.kind === "turnkey_apartments"
	);
	const own_arrangement_apartments = apartments?.filter(
		(item) => item?.acfApartments?.kind === "own_arrangement_apartments"
	);

	return (
		<section id="oferta-section" className={mainClass}>
			<div className="container">
				<div className={`${mainClass}__header`}>
					<h2>Oferta</h2>
					{/* <p
            dangerouslySetInnerHTML={{
              __html: header,
            }}
          /> */}
				</div>
				{/* <div className={`${mainClass}__list`}>
					{data?.map(({ header, items }, index) => (
						<div
							key={index}
							className={`${mainClass}__list__column`}
						>
							<h3
								dangerouslySetInnerHTML={{
									__html: header,
								}}
							/>
							<div
								className={`${mainClass}__list__column__items`}
							>
								{items?.map(({ text }, index) => (
									<div
										key={index}
										className={`${mainClass}__list__column__items__item`}
									>
										<CheckmarkIcon />
										<span
											dangerouslySetInnerHTML={{
												__html: text,
											}}
										/>
									</div>
								))}
							</div>
						</div>
					))}
				</div> */}
				{/* <Apartments
					title="Umowa inwestycyjna"
					sub_title="Zainwestuj krótkoterminowo"
					features_data={turnkey_apartments_items}
					apartments={turnkey_apartments}
				/> */}
				<Apartments
					title="Mieszkana do własnej aranżacji"
					sub_title="Samodzielne zarządzanie"
					features_data={own_arrangement_items}
					apartments={own_arrangement_apartments}
				/>
			</div>
		</section>
	);
};

export default Offer;
