import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

import ArrowIcon from "icons/arrow.svg";

import ContactForm from "components/ContactForm";

const mainClass = "home-hero";

const Hero = ({ left_text, right_text }) => {
  return (
    <section className={mainClass}>
      <div className={`${mainClass}__middle`}>
        <ContactForm />
      </div>
      <div className={`${mainClass}__bottom`}>
        <div className={`${mainClass}__bottom__left`}>
          <h1
            dangerouslySetInnerHTML={{
              __html: left_text,
            }}
          />
          <span
            dangerouslySetInnerHTML={{
              __html: right_text,
            }}
          />
          <Link to="/#oferta">
            Wybierz apartament
            <ArrowIcon />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Hero;
