import "./styles.scss";

import React from "react";

import FeaturesGrid from "components/FeaturesGrid";
import ApartmentsGrid from "../ApartmentsGrid";

const mainClass = "apartments";

const Apartments = ({ title, sub_title, features_data, apartments }) => {
  return (
    <div className={mainClass}>
      <div className={`${mainClass}__header`}>
        <h2>{title}</h2>
        <span>{sub_title}</span>
      </div>
      <FeaturesGrid data={features_data} />
      <ApartmentsGrid data={apartments} />
    </div>
  );
};

export default Apartments;
